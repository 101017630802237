import { PersonCircle } from 'react-bootstrap-icons';
import { Link, Outlet } from 'react-router-dom';

const SiteNav = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-md site-nav sticky-top"
        data-bs-theme="dark"
      >
        <div className="container site-menu">
          <div className="navbar-brand me-auto">
            <Link to="/" className="btn nav-link" type="button">
              <img alt="Meal Buddy" src="/mb-logo-h300-light.png" height={28} />
            </Link>
          </div>
          <div className="navbar-nav">
            <Link to="/login" className="btn nav-link" type="button">
              Log In &nbsp; <PersonCircle size={20} />
            </Link>
          </div>
          <div className="navbar-nav">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
              aria-controls="offcanvas"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvas"
            aria-labelledby="offcanvasLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasLabel">
                Site Menu
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="navbar-nav flex-grow-1 justify-content-between">
                {/* <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </button> */}
                <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/product" className="nav-link">
                    Product
                  </Link>
                </button>
                <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </button>
                <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/support" className="nav-link">
                    Support
                  </Link>
                </button>
                <hr />
                <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/privacy" className="nav-link">
                    Privacy
                  </Link>
                </button>
                <button className="nav-link" data-bs-dismiss="offcanvas">
                  <Link to="/terms" className="nav-link">
                    Terms
                  </Link>
                </button>
              </div>

              {/* <ul className="navbar-nav flex-grow-1 justify-content-between">
                <li className="nav-item">
                  <Link to="/product" className="nav-link">
                    Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/support" className="nav-link">
                    Support
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </nav>

      <Outlet />

      <footer className="container pt-3 border-top">
        <div className="small row row-cols-1 row-cols-md-6 mb-3">
          <div className="col text-center link-secondary text-decoration-none mb-4 mb-md-0">
            <Link to="/product" className="btn nav-link" type="button">
              PRODUCT
            </Link>
          </div>
          <div className="col text-center link-secondary text-decoration-none mb-4 mb-md-0">
            <Link to="/about" className="btn nav-link" type="button">
              ABOUT
            </Link>
          </div>
          <div className="col text-center link-secondary text-decoration-none mb-4 mb-md-0">
            <Link to="/support" className="btn nav-link" type="button">
              SUPPORT
            </Link>
          </div>
          <div className="col text-center link-secondary text-decoration-none mb-4 mb-md-0">
            <Link to="/privacy" className="btn nav-link" type="button">
              PRIVACY
            </Link>
          </div>
          <div className="col text-center link-secondary text-decoration-none mb-4 mb-md-0">
            <Link to="/terms" className="btn nav-link" type="button">
              TERMS
            </Link>
          </div>
          <div className="col text-center link-secondary text-decoration-none">
            <span>&copy; 2024 Cookware&trade;</span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default SiteNav;
