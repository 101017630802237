const AdBlock = ({ size = 'square' }) => {
  //   // For a square ad:
  // <AdBlock />

  // // For a vertical ad:
  // <AdBlock size="vertical" />

  // // For a horizontal ad:
  // <AdBlock size="horizontal" />

  const adSizes = {
    square: {
      slot: '2375399471',
      format: 'auto',
    },
    vertical: {
      slot: '5465158327',
      format: 'auto',
    },
    horizontal: {
      slot: '4399488666',
      format: 'auto',
    },
    // Easily add more sizes here
    // newSize: {
    //   slot: 'YOUR_NEW_SIZE_AD_SLOT_ID',
    //   format: 'auto', // or a specific format like 'rectangle'
    // },
  };

  const currentSize = adSizes[size] || adSizes.square; // Default to 'square'

  return (
    <div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1241978851503087"
        crossOrigin="anonymous"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1241978851503087"
        data-ad-slot={currentSize.slot}
        data-ad-format={currentSize.format}
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
};

export default AdBlock;
