import { Table, Input } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { DataContext } from './DataProvider';
import { Alert, Button } from 'react-bootstrap';
import { ArrowClockwise, InfoCircle } from 'react-bootstrap-icons';

const History = ({ showWelcomeMsg }) => {
  const { colorPref, updateUserPreffs, history, loadHistory } =
    useContext(DataContext);

  const [filter, setFilter] = useState('');
  const [showHistWelcomeMsg, setShowHistWelcomeMessage] = useState(false);

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowHistWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showHistWelcomeMsg) {
      updateUserPreffs({
        seenHistWelcomeMessage: true,
      });
    }
    setShowHistWelcomeMessage(false);
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => new Date(b.date) - new Date(a.date),
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    // },
    {
      title: 'Description',
      dataIndex: 'desc',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => {
      //   // Extract the string value from the Desc component
      //   const aDesc = a.desc.props.children;
      //   const bDesc = b.desc.props.children;
      //   return aDesc.localeCompare(bDesc);
      // },
    },
  ];

  const tableData = history?.map((event, index) => {
    const firebaseTimestamp = new Date(
      event.timestamp.seconds * 1000 + event.timestamp.nanoseconds / 1000000
    );
    // const fb_date = firebaseTimestamp.toDateString();
    // const fb_time = firebaseTimestamp.toLocaleTimeString();
    // const timestamp = fb_date + ' ' + fb_time;

    const desc = () => {
      if (event.event_type === 'shopping') {
        const qty =
          event.event_data.qty > 1 ? '(' + event.event_data.qty + ')' : '';
        return `${event.event_data.item} ${qty}`;
      } else if (event.event_type === 'meal') {
        const sides =
          event.event_data.sides.length > 1 && event.event_data.sides[0] !== ''
            ? ' w/ ' + event.event_data.sides.join(', ')
            : '';
        return event.event_data.name + sides;
      } else if (event.event_type === 'create_account') {
        return event.event_data.message;
      } else {
        return 'N/A';
      }
    };

    const date = () => {
      if (event.event_type === 'shopping' || event.event_type === 'meal') {
        return event.event_data.date;
      } else {
        return firebaseTimestamp.toLocaleDateString();
      }
    };

    return {
      key: index,
      type: event.event_type,
      desc: desc(),
      date: date(),
    };
  });

  const filteredData = tableData.filter((event) =>
    event.desc.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="mt-4 mb-5 container-fluid">
      {showHistWelcomeMsg && (
        <Alert
          className="small"
          variant={colorPref}
          onClose={ackWelcomeMsg}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to History</strong>
          </Alert.Heading>
          Your personalized meal and shopping history. Here you can explore your
          past week's menus and track your grocery purchases.
          <br />
          <br />
          Every time you advance your menu or check off an item on your shopping
          list, we'll save it here for you. Use this information to understand
          your eating habits, discover favorite meals, and streamline your
          grocery shopping.
        </Alert>
      )}
      <div className={'mb-2 fs-1 text-' + colorPref}>
        History{' '}
        <sup>
          <InfoCircle
            size={12}
            className="pointer"
            onClick={() => setShowHistWelcomeMessage(true)}
          />
        </sup>
        <div style={{ float: 'right' }}>
          <Button
            size="sm"
            className="pb-2"
            variant={colorPref}
            onClick={loadHistory}
          >
            <ArrowClockwise size={18} />
          </Button>
        </div>
      </div>
      <div className="my-2">
        <Input
          placeholder="Filter by description"
          onChange={handleInputChange}
        />
      </div>

      <Table
        columns={tableColumns}
        dataSource={filteredData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(r) => r.key}
        // onRow={(r) => {
        //   return {
        //     onClick: () => handleSelectItem(r.item),
        //   };
        // }}
      />
    </div>
  );
};

export default History;
