import { useContext, useEffect, useState } from 'react';
import { DataContext } from './DataProvider';
import { AuthContext } from './AuthProvider';
import MenuWeekPicker from './MenuWeekPicker';
import {
  PencilSquare,
  PlusSquareFill,
  Sliders,
  XCircle,
  PersonFill,
  InfoCircle,
  LockFill,
  UnlockFill,
  QuestionCircle,
} from 'react-bootstrap-icons';
import { Tooltip } from 'antd';

const NameDropper = () => {
  const nameList = [
    'Spicy Taco',
    'Creamy Pasta',
    'Crispy Chicken',
    'Sweet Cupcake',
    'Savory Steak',
    'Juicy Burger',
    'Fluffy Pancake',
    'Tangy Lemon',
    'Smoky BBQ',
    'Baked Potato',
    'Grilled Cheese',
    'Roasted Almond',
    'Buttery Croissant',
    'Honeyed Apple',
    'Zesty Orange',
    'Frosted Donut',
    'Peppered Salmon',
    'Whipped Cream',
    'Sugary Biscuit',
    'Crunchy Cereal',
    'Marinated Tofu',
    'Glazed Ham',
    'Golden Biscuit',
    'Steamed Dumpling',
    'Fresh Salad',
    'Pickled Cucumber',
    'Cheesy Nachos',
    'Rich Brownie',
    'Spiced Chai',
    'Boiled Egg',
    'Buttered Roll',
    'Fruity Smoothie',
    'Melted Chocolate',
    'Toasted Bagel',
    'Seasoned Fries',
    'Velvety Soup',
    'Candied Walnut',
    'Juiced Carrot',
    'Infused Tea',
    'Warm Muffin',
    'Tangy Yogurt',
    'Grated Cheese',
    'Tender Ribs',
    'Buttery Popcorn',
    'Sweetened Milk',
    'Toasted Marshmallow',
    'Simmered Stew',
    'Caramelized Onion',
    'Diced Tomato',
    'Braised Beef',
    'Poached Pear',
  ];
  return nameList[Math.floor(Math.random() * nameList.length)];
};

const Settings = ({ photoURL }) => {
  const {
    colorPref,
    setColorPref,
    updateUserPreffs,
    acctPrefs,
    updateAccountPrefs,
    addNewMenu,
    logout,
  } = useContext(DataContext);
  const { currentUser, currentAccount, removeAccount } =
    useContext(AuthContext);

  const [editAccountName, setEditAccountName] = useState(false);
  const [editMenuWeek, setEditMenuWeek] = useState(false);
  const [editMember, setEditMember] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [members, setMembers] = useState(acctPrefs.members);
  const [newMember, setNewMember] = useState('');
  const [showMemberInfo, setShowMemberInfo] = useState(false);
  const [showDangerZone, setShowDangerZone] = useState(false);
  const [readyToDelete, setReadyToDelete] = useState(false);
  const [reallyReadyToDelete, setReallyReadyToDelete] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (acctPrefs.name) {
      setAccountName(acctPrefs.name);
    } else {
      const newName = NameDropper(); // give the account a random name
      setAccountName(newName);
      updateAccountPrefs({
        name: newName,
      });
    }

    acctPrefs.owner === currentUser.email
      ? setIsOwner(true)
      : setIsOwner(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColorClick = (e) => {
    const color = e.target.className.split('-')[1];
    setColorPref(color);
    updateUserPreffs({
      color_pref: color,
    });
  };

  const handleDaySelect = async (e) => {
    await addNewMenu(e.target.value);
    setEditMenuWeek(false);
  };

  const handleNameChange = () => {
    updateAccountPrefs({
      name: accountName,
    });
    setEditAccountName(false);
  };

  const handleInputChange = (e) => {
    setAccountName(e.target.value);
  };

  const addMember = () => {
    if (newMember === '') {
      setMembers([...members.filter((m) => m !== '')]);
      setEditMember(false);
      return;
    }
    const newMemberList = [...members.filter((m) => m !== ''), newMember];
    setMembers(newMemberList);
    setNewMember('');
    updateAccountPrefs({
      members: newMemberList,
    });
    setEditMember(false);
  };

  const removeMember = (member) => {
    const newMemberList = members.filter((m) => m !== member);
    setMembers(newMemberList);
    updateAccountPrefs({
      members: newMemberList,
    });
  };

  const addNewMember = () => {
    setMembers([...members, '']);
    setNewMember('');
    setEditMember(true);
  };

  const isPrimary = colorPref === 'primary' ? true : false;
  const isInfo = colorPref === 'info' ? true : false;
  const isSuccess = colorPref === 'success' ? true : false;
  const isDanger = colorPref === 'danger' ? true : false;
  const isWarning = colorPref === 'warning' ? true : false;
  const isSecondary = colorPref === 'secondary' ? true : false;
  const isDark = colorPref === 'dark' ? true : false;

  const useCopyToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async (content) => {
      try {
        await navigator.clipboard.writeText(content);
        setIsCopied(true);
        // console.log('Copied to clipboard:', content);
      } catch (error) {
        setIsCopied(false);
        console.error('Unable to copy to clipboard:', error);
      }
    };

    return { isCopied, copyToClipboard };
  };

  const CopyMessageButton = () => {
    const { isCopied, copyToClipboard } = useCopyToClipboard();

    return (
      <button
        className="btn btn-sm btn-dark"
        style={{ float: 'right' }}
        type="button"
        onClick={() =>
          copyToClipboard(document.getElementById('email-template').innerHTML)
        }
      >
        {isCopied ? 'Copied' : 'Copy Message'}
      </button>
    );
  };

  const useCopyUrlToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async (content) => {
      try {
        await navigator.clipboard.writeText(content);
        setIsCopied(true);
        // console.log('Copied to clipboard:', content);
      } catch (error) {
        setIsCopied(false);
        console.error('Unable to copy to clipboard:', error);
      }
    };

    return { isCopied, copyToClipboard };
  };

  const CopyUrlButton = () => {
    const { isCopied, copyToClipboard } = useCopyUrlToClipboard();

    return (
      <button
        className="btn btn-sm btn-dark mx-2"
        style={{ float: 'right' }}
        type="button"
        onClick={() =>
          copyToClipboard(document.getElementById('url-template').innerHTML)
        }
      >
        {isCopied ? 'Copied' : 'Copy Link Only'}
      </button>
    );
  };

  const handleRemoveAccount = async () => {
    setRemoving(true);
    (await removeAccount(currentAccount, currentUser.email)) && logout('/');
  };

  const handleShowDangerZone = () => {
    if (showDangerZone) {
      setShowDangerZone(false);
      setReadyToDelete(false);
      setReallyReadyToDelete(false);
      setRemoving(false);
    } else {
      setRemoving(false);
      setReadyToDelete(false);
      setReallyReadyToDelete(false);
      setShowDangerZone(true);
    }
  };

  return (
    <div className="mt-4 mb-5 container-fluid">
      <div>
        <div className="container mb-2">
          {photoURL && (
            <div style={{ float: 'right' }}>
              <img src={photoURL} className="profile-pic" alt="avatar" />
            </div>
          )}
          <div className={'h1 pb-2 mb-0 text-' + colorPref}>
            Settings <Sliders size={24} />
          </div>
          <p className="text-muted small">
            Below are the settings for this account. Only the owner has
            permission to change the settings.
          </p>
        </div>
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>
            Owner{' '}
            <Tooltip
              placement="right"
              title="The primary email address for this account."
              trigger={['click', 'hover']}
            >
              <span className="pointer">
                <sup>
                  <QuestionCircle size={12} />
                </sup>
              </span>
            </Tooltip>
          </b>
        </div>
        <div className="col-8">{acctPrefs.owner}</div>
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>Status</b>
        </div>
        <div className="col-8">{acctPrefs.status || 'Open'}</div>
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>Open Date</b>
        </div>
        <div className="col-8">
          {new Date(acctPrefs.timestamp.seconds * 1000).toDateString()}
        </div>
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>Type</b>
        </div>
        <div className="col-8">{acctPrefs.type || 'Free with ads'}</div>
      </div>

      <div className="container row settings-row">
        {!editMenuWeek ? (
          <>
            <div className="col-4">
              <b>Menu Start</b>
            </div>
            <div className="col-4">{acctPrefs.menuWeek[0]}</div>
            <div className="col-4 text-end">
              {isOwner && (
                <PencilSquare
                  className="pointer"
                  size={22}
                  onClick={() => setEditMenuWeek(true)}
                />
              )}
            </div>
          </>
        ) : (
          <div className="col-12 pb-3">
            <MenuWeekPicker
              handleDaySelect={handleDaySelect}
              startDay={acctPrefs.menuWeek[0]}
            />
          </div>
        )}
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>
            Name{' '}
            <Tooltip
              placement="right"
              title="A friendly name for this account."
              trigger={['click', 'hover']}
            >
              <span className="pointer">
                <sup>
                  <QuestionCircle size={12} />
                </sup>
              </span>
            </Tooltip>
          </b>
        </div>
        {editAccountName ? (
          <>
            <div className="col-6">
              <input
                type="text"
                value={accountName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-2 text-end">
              <button
                className={'small btn btn-sm px-1 py-0 pb-1 btn-' + colorPref}
                type="button"
                onClick={handleNameChange}
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="col-6">{acctPrefs.name}</div>
            <div className="col-2 text-end">
              {isOwner && (
                <PencilSquare
                  className="pointer"
                  size={22}
                  onClick={() => setEditAccountName(true)}
                />
              )}
            </div>
          </>
        )}
      </div>

      <div className="container row settings-row">
        <div className="col-4">
          <b>
            Members{' '}
            {isOwner && (
              <span
                className="pointer"
                onClick={() => setShowMemberInfo(!showMemberInfo)}
              >
                <sup>
                  <InfoCircle size={12} />
                </sup>
              </span>
            )}
          </b>
        </div>

        <div className="col-8">
          {members.map((member) => {
            return (
              <div key={member} className="mb-1 row">
                {member !== acctPrefs.owner ? (
                  <>
                    {member === '' ? (
                      <>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={newMember}
                            placeholder="name@gmail.com"
                            onChange={(e) => setNewMember(e.target.value)}
                          />
                          &nbsp;&nbsp;
                          <button
                            className={
                              'small btn btn-sm px-1 py-0 pb-1 btn-' + colorPref
                            }
                            type="button"
                            onClick={addMember}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-1">
                          <PersonFill size={16} />
                          &nbsp;{member} &nbsp;
                          {isOwner && (
                            <XCircle
                              size={16}
                              onClick={() => removeMember(member)}
                              className="pointer"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <div className="col-10">{member}</div> */}
                    {/* <div className="col-2 p-0"> */}
                    <div className="text-end">
                      {!editMember && isOwner && (
                        <>
                          Tap to add &rarr; &nbsp;
                          <PlusSquareFill
                            size={22}
                            onClick={addNewMember}
                            className="pointer"
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {acctPrefs.members.length > 1 && <div className="pb-2"></div>}
        </div>
      </div>

      {showMemberInfo && (
        <div className="container row settings-row">
          <div className="text-muted small py-2">
            <b>Share the love!</b> 😍 Let friends and family use this account
            too. Members have permission to change everything except the account
            settings.
            <div className="mb-2"></div>
            Add their Google email address and then send a message asking them
            to join Meal Buddy&trade;. They will need this CODE:{' '}
            <b>{currentAccount}</b>, to join this account when they sign in.
            <div className="mb-2"></div>
            We've prepared a sample message and a magic link that will
            automatically connect new members to this account. Click to copy the
            message or link below and paste it into your favorite messaging app.
            <div className="mb-2"></div>
            <pre
              id="email-template"
              className="px-2 py-1 mb-1"
              style={{ whiteSpace: 'pre-line', border: '1px solid #ccc' }}
            >
              Join me on Meal Buddy&trade;! Go to
              https://meal-buddy.com/join?id={currentAccount} and log in with
              your Google account.
            </pre>
            <pre id="url-template" className="d-none">
              https://meal-buddy.com/join?id={currentAccount}
            </pre>
            <CopyMessageButton />
            <CopyUrlButton />
          </div>
        </div>
      )}

      <div className="container row settings-row">
        <div className="col-4">
          <b>Theme Color</b>
        </div>
        <div className="col-8 pr-0 mr-0">
          <div>
            <div className="d-flex justify-content-between">
              <span
                className={
                  'dot bg-primary' + (isPrimary ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-info' + (isInfo ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-success' + (isSuccess ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-danger' + (isDanger ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-warning' + (isWarning ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-secondary' +
                  (isSecondary ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
              <span
                className={
                  'dot bg-dark' + (isDark ? ' selected-theme-color' : '')
                }
                onClick={handleColorClick}
              ></span>
            </div>
          </div>
        </div>
      </div>

      {isOwner && (
        <div className="container row settings-row">
          <div className="col-12 px-0">
            {!showDangerZone ? (
              <LockFill
                size={22}
                className="mx-2 pointer"
                onClick={handleShowDangerZone}
              />
            ) : (
              <UnlockFill
                size={22}
                className="mx-2 pointer"
                onClick={handleShowDangerZone}
              />
            )}
            <b className="small text-muted">
              &larr; Tap to unlock the DANGER ZONE! Be Careful...
            </b>
            {showDangerZone && (
              <div className="row small my-2 mx-4 text-danger">
                <div className="col-2 col-md-1">
                  <input
                    className="form-check-input mx-2"
                    style={{ height: '20px', width: '20px' }}
                    type="checkbox"
                    id="deleteAccountCheck"
                    onClick={() => setReadyToDelete(!readyToDelete)}
                  />
                </div>
                <div className="col-10 col-md-11 pt-1">
                  <b>DELETE THIS ACCOUNT AND ALL DATA</b>
                </div>
              </div>
            )}
            {showDangerZone && readyToDelete && (
              <>
                <div className="row small my-2 mx-4 text-danger">
                  <div className="col-2 col-md-1">
                    <input
                      className="form-check-input mx-2"
                      style={{ height: '20px', width: '20px' }}
                      type="checkbox"
                      id="deleteAccountCheck"
                      onClick={() =>
                        setReallyReadyToDelete(!reallyReadyToDelete)
                      }
                    />
                  </div>
                  <div className="col-10 col-md-11 pt-1">
                    <b>
                      Yes, really. Delete everything and forget that I was ever
                      here 👋 I understand this decision is FINAL and cannot be
                      reversed. I am gonzo... headed to Splitsville. Hasta la
                      vista, buddy! Peace out ✌️
                    </b>
                  </div>
                </div>
                <button
                  className="form-control mt-2 btn btn-danger"
                  type="button"
                  disabled={!reallyReadyToDelete || removing}
                  onClick={handleRemoveAccount}
                >
                  {removing ? (
                    <div>
                      <div className="spinner-border spinner-border-sm mx-2" />
                      Shredding your data... 🔥🔥🔥
                    </div>
                  ) : (
                    '💀 GAME OVER - PULL THE PLUG! 💀'
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
