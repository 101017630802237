import { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import { DataContext } from './DataProvider';
import { Tabs } from 'antd';
import { InfoCircle, List, Magic, Trash } from 'react-bootstrap-icons';
import MenuWeekPicker from './MenuWeekPicker';
import Select from 'react-select';
import { Badge, Button, Modal, Alert, Form } from 'react-bootstrap';

const MealSlip = ({
  menuSlot,
  mealData,
  menuWeek,
  menuDate,
  colorPref,
  onSelectItem,
}) => {
  const day = menuSlot < 8 ? menuWeek[menuSlot - 1] : menuWeek[menuSlot - 8];
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date().getDay();
  const weekday = daysOfWeek[today];
  const handleClick = () => onSelectItem(menuSlot, mealData, menuDate);

  return (
    <div
      className="mb-1 list-group-item list-group-item-action menu-slot"
      onClick={handleClick}
    >
      <div
        className={'text-' + colorPref + ' text-end'}
        style={{ float: 'right', minWidth: '2.5em' }}
      >
        {day === weekday && menuSlot < 8 ? (
          <small>&raquo; {day}</small>
        ) : (
          <small>{day}</small>
        )}
        <br />
        <span className="smaller">{menuDate}</span>
      </div>

      <div className="w-100">
        {mealData?.hasOwnProperty('meal') ? (
          <>
            {day === weekday && menuSlot < 8 ? (
              <p
                style={{ backgroundColor: 'lightgoldenrodyellow' }}
                className="fs-5 mealButton mb-0"
              >
                {mealData.meal.name}
              </p>
            ) : (
              <p className="fs-5 mealButton mb-0">{mealData.meal.name}</p>
            )}
            {mealData.meal.sides.length > 0 &&
              mealData.meal.sides[0] !== '' && (
                <div className="text-muted">
                  w/{mealData.meal.sides.join(', ')}
                </div>
              )}
          </>
        ) : (
          <p className="addMealButton">Tap to Add</p>
        )}
      </div>
    </div>
  );
};

const MenuList = ({
  menuWeek,
  menuStart,
  dinnerData,
  openModal,
  colorPref,
  startSlot,
  endSlot,
}) => {
  if (menuWeek?.length > 0) {
    return (
      <div className="list-group pb-4">
        {Array.from({ length: endSlot - startSlot + 1 }, (_, i) => (
          <MealSlip
            key={i + startSlot}
            menuSlot={i + startSlot}
            menuWeek={menuWeek}
            menuDate={DateConverter(menuStart, i + startSlot)}
            colorPref={colorPref}
            mealData={dinnerData.meals.find(
              (meal) => meal && meal.slotNum === i + startSlot
            )}
            onSelectItem={openModal}
          />
        ))}
      </div>
    );
  }
};

const DateConverter = (dateString, menuSlot) => {
  if (!dateString) return;
  const stringToDate = (dateString) => {
    menuSlot -= 1;
    const [month, day, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day + menuSlot);
    // return new Date(year, month - 1, day) - menuSlot - 1;
    // console.log('date:', date);
    return date;
  };

  const formatDateToMMDD = (date) => {
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate(); // Get the day of the month

    return `${month}/${day}`;
  };

  const convertedDate = stringToDate(dateString);
  // console.log('convertedDate:', convertedDate);
  return formatDateToMMDD(convertedDate);
};

const LeftoverLol = ({ colorPref }) => {
  const lol = [
    'Why did the leftover soup form a band? It wanted to make some stew-pendous music.',
    "Why don't leftovers ever win an argument? They always get reheated.",
    'How do leftovers stay in shape? They do microwave stretches.',
    "What's a leftover's favorite music genre? Anything with a beet.",
    "What's a leftover's favorite type of movie? A re-run.",
    "What's a leftover's favorite game? Hot potato.",
    "What's a leftover's favorite exercise? Squash.",
    "I need to stop making jokes about leftover food so I'm going to try quitting cold turkey.",
    'The waiter came up and said "Do you want a box for the leftovers?". I said "No, but I\'ll arm wrestle you for it! 💪',
  ];

  if (Math.floor(Math.random() * 14) === 7) {
    return (
      <p className={'mt-4 mb-0 fw-light text-' + colorPref}>
        😂🤣😂
        <br />
        <b>lol:</b> {lol[Math.floor(Math.random() * lol.length)]}
      </p>
    );
  } else {
    return <></>;
  }
};

const Menus = ({ showWelcomeMsg }) => {
  // console.log('run menus...');
  const {
    menus,
    addNewMenu,
    updateMenu,
    updateMenuStartDate,
    meals,
    colorPref,
    updateUserPreffs,
    acctPrefs,
    bulkUpdateHistory,
  } = useContext(DataContext);

  const { currentUser } = useContext(AuthContext);

  const [hasMenu, setHasMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deletable, setDeletable] = useState(false);
  const [selectable, setSelectble] = useState(true);
  const [showMenuWelcomeMsg, setShowMenuWelcomeMessage] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [advanceModalIsOpen, setAdvanceModalIsOpen] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedMealData, setSelectedMealData] = useState(null);
  const [writeInMeal, setWriteInMeal] = useState(null);
  const [leftovers, setLeftovers] = useState([]);
  const [mealInSlot, setMealInSlot] = useState(false);
  const [mealDate, setMealDate] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [clearMenuEnabled, setClearMenuEnabled] = useState(false);
  const [readyToAdvance, setReadyToAdvance] = useState(false);
  const [showAddMealInfo, setShowAddMealInfo] = useState(false);

  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));
  const leftOverSortedMeals = [...leftovers, ...sortedMeals];
  const dinnerData = menus.find((item) => item.id === 'dinner');
  const navigate = useNavigate();
  const menuWeek = acctPrefs?.menuWeek;
  const menuStart = acctPrefs?.menuStart;

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowMenuWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showWelcomeMsg) {
      updateUserPreffs({
        seenMenuWelcomeMessage: true,
      });
    }
    setShowMenuWelcomeMessage(false);
  };

  useEffect(() => {
    if (menuWeek && menuWeek.length > 0) {
      setHasMenu(true);
    } else {
      setHasMenu(false);
    }
    setLoading(false);
    setDeletable(false);
  }, [menuWeek]);

  useEffect(() => {
    if (!menuStart) return;
    const isMoreThan7DaysAfter = (dateString) => {
      // Convert the input date string (mm/dd/yyyy) to a Date object
      const [month, day, year] = dateString.split('/').map(Number);
      const givenDate = new Date(year, month - 1, day); // Create a Date object from the given date

      // Get today's date
      const today = new Date();

      // Calculate the difference in time (milliseconds) between today and the given date
      const timeDifference = today.getTime() - givenDate.getTime();

      // Convert the time difference to days
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days

      // Return true if the difference is more than 7 days
      return daysDifference > 7;
    };
    setReadyToAdvance(isMoreThan7DaysAfter(menuStart));
  }, [menuStart]);

  const openModal = (slot, data, menuDate) => {
    setCurrentSlot(parseInt(slot));
    addLeftovers(parseInt(slot));
    setSelectedMealData(data);
    setSelectedMeal(data?.meal?.name);
    setSelectble(true);
    setWriteInMeal(null);
    setShowAddMealInfo(false);
    setMealDate(menuDate);
    data?.meal?.name && setMealInSlot(true);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentSlot(null);
    setMealInSlot(false);
    setMealDate(null);
    setSelectedMeal(null);
    setSelectedMealData(null);
    setDeletable(false);
  };

  const openAdvanceModal = () => {
    setAdvanceModalIsOpen(true);
  };

  const closeAdvanceModal = () => {
    setAdvanceModalIsOpen(false);
  };

  const handleWriteInInputChange = (e) => {
    if (e.target.value === '') setSelectble(true);
    else setSelectble(false);
    setWriteInMeal(e.target.value);
  };

  const handleWriteInSave = async () => {
    const updatedMenu = [...dinnerData.meals];
    updatedMenu[currentSlot - 1] = {
      slotNum: currentSlot,
      meal: {
        writeIn: true,
        name: writeInMeal,
        sides: [],
      },
    };
    updateMenu(updatedMenu);
    closeModal();
  };

  const handleSelectSave = async (meal) => {
    // console.log('run handleSave...');
    var mealData = null;
    const thisMeal = meals.find((m) => m.name === meal);
    if (!thisMeal) {
      mealData = leftovers.find((m) => m.name === meal);
    } else {
      mealData = thisMeal;
    }
    const { id, ...rest } = mealData;
    const updatedMenu = [...dinnerData.meals];
    updatedMenu[currentSlot - 1] = {
      slotNum: currentSlot,
      meal: rest,
    };
    updateMenu(updatedMenu);
    closeModal();
  };

  const handleRemove = async () => {
    const updatedMenu = [...dinnerData.meals];
    updatedMenu[currentSlot - 1] = null;
    updateMenu(updatedMenu);
    setMealInSlot(false);
  };

  const advanceMenu = async () => {
    // add last week's meals to the history collection
    const meals = dinnerData.meals;
    const [month, day, year] = menuStart.split('/').map(Number);
    const menuStartDate = new Date(year, month - 1, day);
    const mealHistoryData = meals
      .filter((meal) => meal)
      .filter((meal) => meal.slotNum >= 1 && meal.slotNum <= 7)
      .map((meal) => {
        const date = new Date();
        date.setDate(menuStartDate.getDate() + (meal.slotNum - 1));
        return {
          event_data: {
            date: date.toLocaleDateString('en-US'), // format date as YYYY-MM-DD
            name: meal.meal.name,
            sides: meal.meal.sides,
          },
          event_type: 'meal',
          timestamp: date,
        };
      });
    bulkUpdateHistory('meal', mealHistoryData);

    // Extract the meals from index 7 to 13 and update their slotNum if they are not null
    const updatedMenu = meals
      .slice(7, 14)
      .map((item) => (item ? { ...item, slotNum: item.slotNum - 7 } : null));

    // Fill the rest of the array with null values to match the original length
    const filledMenu = [
      ...updatedMenu,
      ...Array(meals.length - updatedMenu.length).fill(null),
    ];

    // console.log(JSON.stringify(filledMenu, null, 2));
    updateMenu(filledMenu);
    updateMenuStartDate(menuWeek[0]);
    closeAdvanceModal();
  };

  const addLeftovers = (slot) => {
    var newLeftovers = [];
    while (slot > 1) {
      const thisSlot = slot;
      const lastMeal = dinnerData.meals.filter(
        (meal) => meal && meal.slotNum === thisSlot - 1
      )[0];
      lastMeal?.meal?.name &&
        !lastMeal.meal.name.startsWith('lo:') &&
        newLeftovers.push({
          name: 'lo: ' + lastMeal.meal.name,
          sides: lastMeal.meal.sides || [],
          shoppingList: [],
          id: '',
        });
      slot--;
    }
    setLeftovers(newLeftovers);
  };

  // Convert meals to options format for react-select
  const mealOptions = leftOverSortedMeals.map((meal) => ({
    value: meal.name,
    label: meal.name,
  }));

  const RecipeTab = () => {
    return (
      <div>
        {!selectedMealData?.meal?.ingredientsList &&
          !selectedMealData?.meal?.instructionsList && <div>No recipe</div>}
        {selectedMealData?.meal?.ingredientsList && (
          <>
            <ul>
              {selectedMealData?.meal?.ingredientsList?.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </>
        )}

        {selectedMealData?.meal?.instructionsList && (
          <>
            <p className="fs-6">Instructions:</p>
            <ol>
              {selectedMealData?.meal?.instructionsList?.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          </>
        )}
      </div>
    );
  };

  const NotesTab = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            selectedMealData?.meal?.notes?.replace(
              /(?:\r\n|\r|\n)/g,
              '<br />'
            ) || 'No notes.',
        }}
      />
    );
  };

  const GroceryListTab = () => {
    return (
      <div>
        <ul>
          {selectedMealData?.meal?.shoppingList?.map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const AiTab = () => {
    return (
      <div>
        <p className="h3">Downloaded from the Web</p>
        <p className="h6">Link to original recipe:</p>
        <a
          href={selectedMealData?.meal?.sourceUrl}
          target="_blank"
          rel="noreferrer"
        >
          {selectedMealData?.meal?.sourceUrl}
        </a>
      </div>
    );
  };

  const tabItems = [
    {
      key: '1',
      label: 'Recipe',
      children: <RecipeTab />,
    },
    {
      key: '2',
      label: 'Notes',
      children: <NotesTab />,
    },
    {
      key: '3',
      label: 'Grocery List',
      children: <GroceryListTab />,
    },
  ];

  // show the magic tab only when adding a meal OR when the source URL is pesent
  selectedMealData?.meal?.sourceUrl &&
    tabItems.push({
      key: '4',
      label: <Magic size={16} />,
      children: <AiTab />,
    });

  // Handle the create menu button click
  const handleCreateMenu = async () => {
    if (startDay) {
      try {
        await addNewMenu(startDay);
        setHasMenu(true);
        navigate('/meals');
      } catch (error) {
        console.error('Error creating menu:', error);
      }
    }
  };

  const handleDaySelect = (e) => {
    setStartDay(e.target.value);
  };

  const clearMenu = async () => {
    await updateMenu([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setClearMenuEnabled(false);
  };

  if (loading) {
    return <></>;
  } else if (!hasMenu) {
    return (
      <div className="mt-4 mb-5 container-fluid text-center">
        <main className="form-signin w-100 m-auto">
          <h1>You're in! 🙌</h1>
          <img
            className="mb-4"
            src="/taco-cheer.webp"
            alt="Dancing Taco"
            width="200"
            height="200"
          />
          <h4 className="fw-normal">👋 Welcome {currentUser?.displayName}</h4>
          <p className="new-menu-text">Let's get this meal party started!</p>
          {/* <p className="new-menu-text">
              Just call yourself butter cause you're on a roll!
            </p> */}
          <p className="new-menu-text">
            You're gonna need a menu. A Meal Buddy menu is a 2-week plan that
            contains 14 meal slots. That's 7 for this week's meals and 7 for
            next week's.
          </p>
          <p className="new-menu-text">
            After shopping, you will advance next week's menu to begin planning
            the following week's meals.
          </p>
          <p className="new-menu-text">
            The cycle never ends. Like Groundhog Day on a loop! We can't stop
            this crazy ride, but Meal Buddy can make it a little easier.
          </p>
          <p className="new-menu-text">After all, you gotta eat! 😋</p>

          <MenuWeekPicker
            startDay={startDay}
            handleDaySelect={handleDaySelect}
          />

          <i className="small">
            <b>Note:</b> You can change this later in settings.
          </i>

          <button
            id="menu-account-button"
            type="button"
            className="btn btn-dark w-100 py-2 mt-4"
            onClick={handleCreateMenu}
          >
            Create My Meal Buddy&trade; Menu
          </button>
          <p className="mt-3 mb-3 text-body-secondary">©2024 Cookware™</p>
        </main>
      </div>
    );
  } else {
    return (
      <div className="mt-4 mb-5 container-fluid">
        {showMenuWelcomeMsg && (
          <Alert
            className="small"
            variant={colorPref}
            onClose={ackWelcomeMsg}
            dismissible
          >
            <Alert.Heading>
              <strong className="me-auto">Welcome to your Menu</strong>
            </Alert.Heading>
            Your personalized meal planning haven. Here you can effortlessly
            plan your dinners for this week and next week. Fill each of the
            daily slots with delicious recipes or planned leftovers. <br />
            <br />
            Tap any filled slot to instantly view its recipe, your personal
            notes, and shopping list. Ready for the grocery store? Add
            everything you'll need for your week's meals to your shopping list
            with a single tap on the Shopping page.
          </Alert>
        )}
        <div>
          {readyToAdvance && (
            <div className="pb-2 mb-2 text-center border-bottom">
              It's time to advance your menu &rarr;&nbsp;
              <Button
                size="sm"
                className="flex-grow-1"
                variant={colorPref}
                onClick={openAdvanceModal}
              >
                <small>Advance Menu</small>
              </Button>
            </div>
          )}
          <div className={'fs-1 pb-1 text-center text-' + colorPref}>
            This Week's Menu{' '}
            <sup>
              <InfoCircle
                size={12}
                className="pointer"
                onClick={() => setShowMenuWelcomeMessage(true)}
              />
            </sup>
          </div>
          <MenuList
            menuWeek={menuWeek}
            menuStart={menuStart}
            dinnerData={dinnerData}
            openModal={openModal}
            colorPref={colorPref}
            startSlot={1}
            endSlot={7}
          />

          <div className={'fs-1 pb-1 text-center text-' + colorPref}>
            Next Week's Menu
          </div>

          <MenuList
            menuWeek={menuWeek}
            menuStart={menuStart}
            dinnerData={dinnerData}
            openModal={openModal}
            colorPref={colorPref}
            startSlot={8}
            endSlot={14}
          />

          <div className="text-end">
            {clearMenuEnabled ? (
              <Button
                size="sm"
                className="flex-grow-1"
                variant={colorPref}
                onClick={clearMenu}
              >
                <small>Clear Menu</small>
              </Button>
            ) : (
              <Button
                size="sm"
                className="flex-shrink-1"
                variant={colorPref}
                onClick={() => setClearMenuEnabled(true)}
              >
                <Trash size={16} />
              </Button>
            )}
          </div>
        </div>

        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header id="add-meal-header" closeButton>
            <Modal.Title className={'text-' + colorPref}>
              {mealInSlot ? (
                'Selected Meal'
              ) : (
                <>
                  Add a Meal{' '}
                  <sup>
                    <InfoCircle
                      size={12}
                      className="pointer"
                      onClick={() => setShowAddMealInfo(!showAddMealInfo)}
                    />
                  </sup>
                  {!mealInSlot && showAddMealInfo && (
                    <>
                      <div className="small mt-2">
                        Welcome meal planner! Here you can easily add a meal to
                        your weekly menu. Choose from any pre-existing
                        favorites, marked as "lo:" for leftovers to simplify
                        your meal prep.
                        <br />
                        <br />
                        Want something new? Write in any meal - perfect for
                        logging restaurant outings or spontaneous bites. Keep in
                        mind that written meals won't have recipes or shopping
                        list details.
                      </div>
                    </>
                  )}
                </>
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <Badge pill bg={colorPref}>
                {currentSlot && currentSlot < 8
                  ? menuWeek[currentSlot - 1]
                  : currentSlot && menuWeek[currentSlot - 8]}
                {currentSlot < 8
                  ? ` ${mealDate} - This Week`
                  : ` ${mealDate} - Next Week`}
              </Badge>
            </div>

            {mealInSlot ? (
              <>
                <p className="fs-1 py-0 my-2">{selectedMeal}</p>
                {selectedMealData?.meal?.sides.length > 0 &&
                  selectedMealData?.meal?.sides[0] !== '' && (
                    <div className="text-muted">
                      w/{selectedMealData.meal.sides.join(', ')}
                    </div>
                  )}
                {selectedMealData?.meal?.name.startsWith('lo:') ||
                selectedMealData?.meal?.writeIn ? (
                  <div className="py-0 my-2">
                    {selectedMealData?.meal?.name.startsWith('lo:') ? (
                      <>
                        This meal is a leftover
                        <LeftoverLol colorPref={colorPref} />
                      </>
                    ) : (
                      <>This meal is a write-in</>
                    )}
                  </div>
                ) : (
                  <Tabs defaultActiveKey="1" items={tabItems} />
                )}
              </>
            ) : (
              <>
                <Select
                  className="mb-2 mt-3"
                  onChange={(e) => handleSelectSave(e.value)}
                  options={mealOptions}
                  placeholder="Select a meal..."
                  disabled={!selectable}
                />
                <div className="text-center" style={{ fontStyle: 'italic' }}>
                  - OR -
                </div>
                <Form.Group className="mb-2 mt-2" controlId="write-in">
                  <Form.Control
                    className="mb-2"
                    type="text"
                    autoComplete="off"
                    placeholder="Write in a meal..."
                    value={writeInMeal || ''}
                    onChange={handleWriteInInputChange}
                  />
                  <Button
                    style={{ float: 'right' }}
                    variant={colorPref}
                    disabled={selectable}
                    onClick={handleWriteInSave}
                  >
                    Save
                  </Button>
                </Form.Group>
              </>
            )}
          </Modal.Body>

          {mealInSlot && (
            <Modal.Footer>
              {deletable && (
                <Button
                  variant={colorPref === 'danger' ? 'warning' : 'danger'}
                  className="mr-2"
                  onClick={handleRemove}
                >
                  Remove from Menu
                </Button>
              )}

              {!deletable && (
                <Button
                  variant={colorPref}
                  className="mr-2"
                  onClick={() => setDeletable(true)}
                >
                  <Trash size={16} />
                </Button>
              )}
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Modal.Footer>
          )}
        </Modal>

        {/* advance modal */}
        <Modal
          show={advanceModalIsOpen}
          onHide={closeAdvanceModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>
              Advance Menu
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="fs-5">
              <b>Heads-up, Buttercup!</b> 🙃
            </p>
            <p>
              You are about to{' '}
              <b>
                <u>REMOVE</u>
              </b>{' '}
              this week's menu and replace it with next week's menu. This action
              is final.
            </p>
            <p>
              Make sure you've added any leftovers to next week's menu before
              pressing the Advance button.
            </p>
            <p>
              Advancing the menu will update the meal history which can be
              viewed from the Options <List /> menu.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant={colorPref} className="mr-2" onClick={advanceMenu}>
              Advance
            </Button>
            <Button
              variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
              onClick={closeAdvanceModal}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default Menus;
