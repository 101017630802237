import { ChatHeart } from 'react-bootstrap-icons';
import AdBlock from '../Utils/AdBlock';

const About = () => {
  return (
    <div className="text-muted container mt-4 mb-5 col-md-7">
      <h2>
        Our Story <ChatHeart size={48} style={{ float: 'right' }} />
      </h2>
      <p>
        Meal Buddy was born out of the everyday struggle that couples and
        families know all too well: What's for dinner? When Chris and Christine
        first moved in together, they quickly realized that meal planning wasn't
        just about picking recipes—it was about balancing tastes, managing
        ingredients, and figuring out how to shop smartly, all while keeping
        things fun and stress-free.
      </p>
      <AdBlock />
      <p>
        After trying a slew of apps and spreadsheets, they found themselves
        frustrated. Nothing quite fit their needs, and the same questions kept
        popping up: What's for dinner? What do we need to make it? Where's the
        recipe? And why does grocery shopping feel like a scavenger hunt?
      </p>
      <p>
        That's when Chris, leveraging his cloud development expertise and
        passion for technology, decided to create Meal Buddy. This tool was
        designed for busy parents, couples, and anyone who thrives on
        organization. But Meal Buddy isn't just practical—it's playful too. It's
        like having a friend in the kitchen (one who doesn't mind a little
        upfront work to make your life wicked easier).
      </p>
      <p>
        Meal Buddy helps you plan meals, organize grocery lists, and streamline
        shopping trips by grouping items by aisle. You'll never double back to
        the produce section because you missed an item on the first pass. From
        adding custom aisle descriptions to saving personalized recipes and
        notes, it's fully customizable for your lifestyle.
      </p>
      <AdBlock />
      <p>
        Whether you're a planner at heart or just trying to stay ahead of
        dinnertime chaos, Meal Buddy brings organization, ease, and a touch of
        fun to your kitchen routine. Let us help you answer the age-old
        question, “What's for dinner?” so you can spend more time enjoying your
        life, not stressing over meals.
      </p>
      <p className="text-center">
        <img
          style={{
            width: '100%',
            maxWidth: '600px',
            border: '1px solid #ccd',
            borderRadius: '10px',
            padding: '2px',
          }}
          src="ccc-600.png"
          alt="Collage"
        />
      </p>
    </div>
  );
};

export default About;
