import React from 'react';

const RefBake = () => {
  return (
    <div className="container mt-3 mb-5">
      <h2>Baking Weights and Measures</h2>
      <table>
        <tr>
          <th>Ingredient</th>
          <th>US Customary</th>
          <th>Metric</th>
        </tr>
        <tr>
          <td>All-Purpose Flour</td>
          <td>1 cup = 4.5 oz = 128 g</td>
          <td>100 g = 0.71 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Cake Flour</td>
          <td>1 cup = 4 oz = 113 g</td>
          <td>100 g = 0.88 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Bread Flour</td>
          <td>1 cup = 5 oz = 142 g</td>
          <td>100 g = 0.7 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Granulated Sugar</td>
          <td>1 cup = 7 oz = 200 g</td>
          <td>100 g = 0.5 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Confectioners' Sugar</td>
          <td>1 cup = 4.5 oz = 128 g</td>
          <td>100 g = 0.78 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Brown Sugar (packed)</td>
          <td>1 cup = 7.5 oz = 213 g</td>
          <td>100 g = 0.47 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Butter</td>
          <td>
            1 cup = 8 oz = 227 g<br />1 stick = 4 oz = 113 g
          </td>
          <td>100 g = 0.44 cup = 3.5 oz</td>
        </tr>
        <tr>
          <td>Eggs (large)</td>
          <td>1 large egg = 1.75 oz = 50 g</td>
          <td>100 g = 2 large eggs</td>
        </tr>
        <tr>
          <td>Water</td>
          <td>1 cup = 8 fl oz = 237 ml</td>
          <td>100 ml = 0.42 cup</td>
        </tr>
        <tr>
          <td>Milk</td>
          <td>1 cup = 8 fl oz = 237 ml</td>
          <td>100 ml = 0.42 cup</td>
        </tr>
      </table>
    </div>
  );
};

export default RefBake;
