import React from 'react';
import { ShieldLock } from 'react-bootstrap-icons';

const Privacy = () => {
  return (
    <div className="smaller text-muted container mt-4 mb-5 col-md-7">
      <h2>
        Privacy Policy <ShieldLock size={48} style={{ float: 'right' }} />
      </h2>

      <p>
        <strong>Effective Date:</strong> September 17, 2024
      </p>

      <p>
        SpyCook Software, LLC ("we," "us," or "our") operates the Meal Buddy
        website and application (the "Service"). We are committed to protecting
        your privacy and explaining how we collect, use, and disclose
        information about you. This Privacy Policy describes our practices in
        connection with information that we collect through the Service.
      </p>

      <p>
        <strong>
          Please read this Privacy Policy carefully. By accessing or using the
          Service, you agree to be bound by this Privacy Policy and our Terms of
          Service, which are incorporated herein by reference.
        </strong>
      </p>

      <h5>1. Information We Collect</h5>

      <h6>Information You Provide to Us:</h6>

      <ul>
        <li>
          <strong>Account Information:</strong> When you create an account, you
          provide us with information such as your name and email address.
        </li>
        <li>
          <strong>User Content:</strong> We collect the content you create and
          post on Meal Buddy, such as your recipes, meals, lists, and notes
          ("User Content").
        </li>
        <li>
          <strong>Communications:</strong> If you contact us directly, we may
          keep a record of the correspondence.
        </li>
      </ul>

      <h6>Information We Collect Automatically:</h6>

      <ul>
        <li>
          <strong>Usage Data:</strong> We automatically collect information
          about your use of the Service, such as the features you access, the
          actions you take, and the time, frequency, and duration of your
          activities.
        </li>
        <li>
          <strong>Device Information:</strong> We may collect information about
          the device you use to access the Service, such as the hardware model,
          operating system, browser type, IP address, unique device identifiers,
          and mobile network information.
        </li>
        <li>
          <strong>Location Information:</strong> If you enable location
          services, we may collect information about the location of your
          device.
        </li>
      </ul>

      <h6>Information We Collect from Third Parties:</h6>

      <ul>
        <li>
          <strong>Google Analytics:</strong> We use Google Analytics to collect
          information about how users interact with the Service. Google
          Analytics uses cookies and similar technologies to collect and analyze
          information, and to track and report on your use of the Service. This
          information is transmitted to and stored by Google on servers in the
          United States. For more information about Google's privacy practices,
          please visit{' '}
          <a href="www.google.com/policies/privacy/partners/">
            www.google.com/policies/privacy/partners/
          </a>
          . You can opt-out of Google Analytics by installing the Google
          Analytics opt-out browser add-on, available at{' '}
          <a href="https://tools.google.com/dl/gaoptout">
            https://tools.google.com/dl/gaoptout
          </a>
          .
        </li>
      </ul>

      <h5>2. How We Use Information</h5>

      <p>We use the information we collect for the following purposes:</p>

      <ul>
        <li>
          <strong>To Provide and Improve the Service:</strong> To operate,
          maintain, improve, and enhance the features and functionality of the
          Service.
        </li>
        <li>
          <strong>To Personalize Your Experience:</strong> To tailor your
          experience on the Service and to provide you with content that is more
          relevant to your interests.
        </li>
        <li>
          <strong>To Communicate with You:</strong> To send you service-related
          communications, such as announcements, administrative messages, and
          updates.
        </li>
        <li>
          <strong>To Provide Customer Support:</strong> To respond to your
          questions and requests.
        </li>
        <li>
          <strong>For Marketing and Advertising:</strong> We may use your
          information, such as your email address and usage data, to send you
          promotional materials about our products and services. You can opt out
          of receiving these communications at any time by following the
          unsubscribe instructions provided in the communication.
        </li>
        <li>
          <strong>To Analyze and Improve the Service:</strong> To monitor and
          analyze the use of the Service, to identify trends, and to understand
          how users interact with the Service.
        </li>
        <li>
          <strong>To Protect the Service and Our Users:</strong> To protect the
          security and integrity of the Service, to prevent fraud and abuse, and
          to enforce our Terms of Service.
        </li>
      </ul>

      <h5>3. How We Share Information</h5>

      <p>
        We do not sell, trade, or otherwise transfer your personally
        identifiable information to third parties for their marketing purposes.
        We may share your information with third parties in the following
        circumstances:
      </p>

      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers that perform services on our behalf,
          such as hosting, data analysis, marketing, and customer support.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information
          if required to do so by law or in the good faith belief that such
          action is necessary to (a) comply with a legal obligation, (b) protect
          and defend our rights or property, (c) prevent or investigate possible
          wrongdoing in connection with the Service, (d) protect the personal
          safety of users of the Service or the public, or (e) protect against
          legal liability.
        </li>
        <li>
          <strong>Business Transfers:</strong> If we are involved in a merger,
          acquisition, or sale of all or a portion of our assets, your
          information may be transferred as part of that transaction. We will
          notify you via email and/or a prominent notice on our website of any
          such change in ownership or control of your information.
        </li>
        <li>
          <strong>Aggregate or De-Identified Information:</strong> We may share
          aggregated or de-identified information that cannot reasonably be used
          to identify you with third parties for any purpose.
        </li>
      </ul>

      <h5>4. Data Security</h5>

      <p>
        We use commercially reasonable security measures to protect against
        unauthorized access to or unauthorized alteration, disclosure, or
        destruction of your information. However, no method of transmission over
        the Internet, or method of electronic storage, is 100% secure.
        Therefore, we cannot guarantee its absolute security.
      </p>

      <h5>5. Children's Privacy</h5>

      <p>
        The Service is not intended for children under 13 years of age, and we
        do not knowingly collect personally identifiable information from
        children under 13. If you are under 13, do not use or provide any
        information on the Service or on or through any of its features. If you
        are a parent or guardian and you are aware that your child under the age
        of 13 has provided us with personal information, please contact us.
      </p>

      <h5>6. Your Choices</h5>

      <ul>
        <li>
          <strong>Account Information:</strong> You can access, update, or
          delete certain account information by logging into your account
          settings.
        </li>
        <li>
          <strong>Cookies:</strong> Most web browsers are set to accept cookies
          by default. You can usually choose to set your browser to remove or
          reject browser cookies.
        </li>
        <li>
          <strong>Marketing Communications:</strong> You can opt out of
          receiving promotional communications from us by following the
          unsubscribe instructions provided in the communication.
        </li>
      </ul>

      <h5>7. Changes to This Privacy Policy</h5>

      <p>
        We may update our Privacy Policy from time to time. We will post any
        changes to this Privacy Policy on this page and update the "Effective
        Date" at the top of this Privacy Policy. You are advised to review this
        Privacy Policy periodically for any changes.
      </p>

      <h5>8. Contact Us</h5>

      <p>
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:info@meal-buddy.com">info@meal-buddy.com</a>.
      </p>
    </div>
  );
};

export default Privacy;
