import React from 'react';
import { FileEarmarkText } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="smaller text-muted container mt-4 mb-5 col-md-7">
      <h2>
        Terms of Service{' '}
        <FileEarmarkText size={48} style={{ float: 'right' }} />
      </h2>

      <p>
        <strong>Last updated:</strong> September 17, 2024
      </p>

      <p>
        These Terms of Service ("Terms") govern your use of the Meal Buddy
        website and application ("Meal Buddy" or the "Service") provided by
        SpyCook Software, LLC, (d/b/a "Cookware") a New Hampshire company ("we,"
        "us," or "our").
      </p>

      <p>
        <strong>
          By accessing or using Meal Buddy, you agree to be bound by these
          Terms. If you disagree with any part of the Terms, then you may not
          access or use the Service.
        </strong>
      </p>

      <h5>1. Using Meal Buddy</h5>

      <ul>
        <li>
          <strong>Eligibility:</strong> You must be at least 13 years old to use
          Meal Buddy. By using the Service, you represent and warrant that you
          are of legal age to form a binding contract.
        </li>
        <li>
          <strong>Account Security:</strong> You are responsible for maintaining
          the confidentiality of your account information. You are responsible
          for all activity that occurs under your account.
        </li>
        <li>
          <strong>User Content:</strong> You retain ownership of the content you
          create and post on Meal Buddy (such as recipes, meals, lists, and
          notes), which we refer to as "User Content." By posting User Content,
          you grant us a non-exclusive, royalty-free, worldwide license to use,
          reproduce, modify, adapt, publish, translate, distribute, and display
          your User Content in connection with the operation and promotion of
          Meal Buddy. You are solely responsible for your User Content and the
          consequences of posting it on the Service.{' '}
        </li>
        <li>
          <strong>Prohibited Activities:</strong> You agree not to:
          <ul>
            <li>Use the Service for any illegal or unauthorized purpose.</li>
            <li>
              Interfere with or disrupt the Service or servers or networks
              connected to the Service.
            </li>
            <li>
              Attempt to gain unauthorized access to any portion of the Service.
            </li>
            <li>
              Collect or harvest any personally identifiable information,
              including account names, from the Service.
            </li>
            <li>
              Use any robots, spiders, crawlers, or other automated means to
              access the Service.
            </li>
          </ul>
        </li>
      </ul>

      <h5>2. Intellectual Property</h5>

      <p>
        The Service and its original content, features, and functionality are
        and will remain the exclusive property of SpyCook Software, LLC and its
        licensors. The Service is protected by copyright, trademark, and other
        laws of both the United States and foreign countries. You agree not to
        reproduce, duplicate, copy, sell, resell, or exploit any portion of the
        Service without our express written permission.
      </p>

      <h5>3. Third-Party Services</h5>

      <p>
        Meal Buddy may contain links to third-party websites or services that
        are not owned or controlled by us. We have no control over, and assume
        no responsibility for, the content, privacy policies, or practices of
        any third-party websites or services. We strongly advise you to review
        the terms of service and privacy policy of any third-party website or
        service that you visit.
      </p>

      <h5>4. Disclaimers</h5>

      <ul>
        <li>
          <strong>"As Is" Service:</strong> Meal Buddy is provided on an "as is"
          and "as available" basis. We make no representations or warranties of
          any kind, express or implied, as to the operation of the Service or
          the information, content, materials, or products included on the
          Service.
        </li>
        <li>
          <strong>No Guarantee:</strong> We do not warrant that the Service will
          be uninterrupted or error-free, and we will not be liable for any
          interruptions or errors.{' '}
        </li>
      </ul>

      <h5>5. Limitation of Liability</h5>

      <p>
        To the fullest extent permitted by law, SpyCook Software, LLC and its
        officers, directors, employees, agents, licensors, and suppliers will
        not be liable for any indirect, incidental, special, consequential, or
        punitive damages, or any loss of profits or revenues, whether incurred
        directly or indirectly, or any loss of data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; or (iv) unauthorized access, use, or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have
        been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.
      </p>

      <h5>6. Privacy Policy</h5>

      <p>
        Our <Link to="/privacy">Privacy Policy</Link> describes how we collect,
        use, and disclose information about you in connection with your use of
        the Service. By using the Service, you consent to our privacy practices
        as described in the Privacy Policy.
      </p>

      <h5>7. Termination</h5>

      <p>
        We may terminate or suspend your access to Meal Buddy immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms.
      </p>

      <h5>8. Governing Law</h5>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of the State of New Hampshire, without regard to its conflict of law
        provisions.
      </p>

      <h5>9. Changes to these Terms</h5>

      <p>
        We reserve the right to update or modify these Terms at any time without
        prior notice. Your continued use of the Service after any changes
        constitutes your acceptance of the new Terms.
      </p>

      <h5>10. Contact Us</h5>

      <p>
        If you have any questions about these Terms, please contact us at
        info@meal-buddy.com.
      </p>
    </div>
  );
};

export default Terms;
