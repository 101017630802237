import { useContext, useState, useEffect } from 'react';
import { DataContext } from '../DataProvider';
import { Table, Input } from 'antd';
import { InfoCircle, PlusSquareFill } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import MealForm from './MealForm';

const Meals = ({ showWelcomeMsg }) => {
  const {
    meals,
    colorPref,
    updateUserPreffs,
    items,
    updateMeal,
    addMeal,
    deleteMeal,
    fetchMealByUrl,
    createItem,
  } = useContext(DataContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentMeal, setCurrentMeal] = useState(false);
  const [filter, setFilter] = useState('');
  const [showMealWelcomeMsg, setShowMealWelcomeMessage] = useState(false);

  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));
  const filteredData = sortedMeals.filter(
    (item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()) ||
      item.sides?.toString().toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    if (showWelcomeMsg) {
      setShowMealWelcomeMessage(true);
    }
  }, [showWelcomeMsg]);

  const ackWelcomeMsg = () => {
    if (showWelcomeMsg) {
      updateUserPreffs({
        seenMealWelcomeMessage: true,
      });
    }
    setShowMealWelcomeMessage(false);
  };

  const openModal = (meal) => {
    const selectedMeal = meals.find((i) => i.name === meal);
    if (selectedMeal) {
      setCurrentMeal(true);
      const ingredientsList =
        selectedMeal.ingredientsList?.length > 0
          ? selectedMeal.ingredientsList.map((item) => ({ name: item }))
          : [];
      const instructionsList =
        selectedMeal.instructionsList?.length > 0
          ? selectedMeal.instructionsList.map((item) => ({ name: item }))
          : [];
      setFormData({ ...selectedMeal, ingredientsList, instructionsList });
    } else {
      setFormData({});
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({});
    setCurrentMeal(false);
  };

  const columns = [
    {
      title: 'Meal Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sides',
      dataIndex: 'sides',
      key: 'sides',
      render: (array) => array.join().replace(/,/g, ', '),
    },
  ];

  const isDuplicateMeal = (name) => {
    return meals.some((meal) => meal.name.toLowerCase() === name.toLowerCase());
  };

  return (
    <div className="mt-4 mb-5 container-fluid">
      {showMealWelcomeMsg && (
        <Alert
          className="small"
          variant={colorPref}
          onClose={ackWelcomeMsg}
          dismissible
        >
          <Alert.Heading>
            <strong className="me-auto">Welcome to Meals</strong>
          </Alert.Heading>
          Here you can create your own custom meals by adding recipes and notes.
          Need to hit the store? We've got you covered. Each meal automatically
          generates a grocery list of all the ingredients you'll need, making
          your shopping trip a breeze.
          <br />
          <br />
          Get creative. Meals can be used to organize groups of items. Use meals
          named "Weekly Staples", "Game Snacks", or "Bob's Lunches" to quickly
          add those items to the shopping list.
        </Alert>
      )}
      <div className="container mb-2">
        <div className="row">
          <div className={'col-4 fs-1 p-0 text-' + colorPref}>
            Meals{' '}
            <sup>
              <InfoCircle
                size={12}
                className="pointer"
                onClick={() => setShowMealWelcomeMessage(true)}
              />
            </sup>
          </div>
          <div className="col-6 mt-2 p-0">
            <Input
              placeholder="Type to filter"
              onChange={(e) => setFilter(e.target.value)}
              className="w-100"
            />
          </div>
          <div className={'col-2 p-0 mt-2 ml-auto text-end text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
              className="pointer"
            />
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        showSorterTooltip={false}
        rowKey={(meal) => meal.name}
        onRow={(meal) => {
          return {
            onClick: () => openModal(meal.name), // click row
          };
        }}
      />

      <MealForm
        formData={formData}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        currentMeal={currentMeal}
        colorPref={colorPref}
        setFormData={setFormData}
        items={items}
        updateMeal={updateMeal}
        addMeal={addMeal}
        deleteMeal={deleteMeal}
        fetchMealByUrl={fetchMealByUrl}
        isDuplicateMeal={isDuplicateMeal}
        createItem={createItem}
      />
    </div>
  );
};

export default Meals;
