import { auth, googleProvider, analytics } from '../../firebase.config';
import { logEvent } from 'firebase/analytics';
import { signInWithPopup } from 'firebase/auth';
import './GLogo.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({
  join,
  useSearchParams,
  accountId,
  setAccountId,
  accountReset,
  setAccountReset,
}) => {
  const [ready2Login, setReady2Login] = useState(false);
  const [ready2Type, setReady2Type] = useState(false);
  const [showTroubleMsg, setShowTroubleMsg] = useState(false);
  const [searchParams] = useSearchParams();
  const accountIdParam = searchParams.get('id');
  const resetUserParam = searchParams.get('fx');

  useEffect(() => {
    if (!join) {
      // default login
      setReady2Login(true);
    } else if (join && accountIdParam) {
      // new user clicked the magic link from the invite
      setAccountId(accountIdParam);
      setReady2Login(true);
      setReady2Type(false);
    } else if (join && !accountIdParam) {
      // new user clicked the "JOIN" link from the default login page
      setReady2Type(true);
      setAccountId('');
      setReady2Login(false);
    }
  }, [accountIdParam, join, setAccountId]);

  const signInWithGoogle = async () => {
    // console.log('sign in with google...');
    try {
      await signInWithPopup(auth, googleProvider);
      logEvent(analytics, 'login', { method: 'google' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    setAccountId(e.target.value);
    e.target.value.length === 20 ? setReady2Login(true) : setReady2Login(false);
  };

  return (
    <div className="text-center mt-4">
      <main className="container">
        <form>
          {join ? (
            <MealTicketLogo
              accountId={accountId}
              handleInputChange={handleInputChange}
              ready2Type={ready2Type}
            />
          ) : (
            <MealBuddyLogo />
          )}
          {resetUserParam && (
            <div
              className="smaller text-danger mt-2 pointer"
              onClick={() => setShowTroubleMsg(!showTroubleMsg)}
            >
              Tap here if you are having trouble logging in
            </div>
          )}
          {showTroubleMsg && (
            <div className="mt-2 p-2" style={{ border: '1px solid #ccd' }}>
              <p className="smaller text-muted text-center">
                Oh SNAP! 😕 Your email no longer has access to the account it
                was associated with. You'll need to contact the account owner
                for more details if you want to restore access.
              </p>
              <p className="smaller text-muted text-center">
                Otherwise, you can remove the association and create a new
                account by checking the box below when logging in.
              </p>
              <input
                className="form-check-input mx-2"
                type="checkbox"
                id="resetCheck"
                onClick={() => setAccountReset(!accountReset)}
              />
              <label className="form-check-label" htmlFor="resetCheck">
                Reset Email and Create a New Account
              </label>
            </div>
          )}
          <div className="mt-4 mb-5">
            <SignInWithGoogleButton
              handleClick={signInWithGoogle}
              ready2Login={ready2Login}
            />
          </div>
        </form>
      </main>
    </div>
  );
};

const SignInWithGoogleButton = ({ handleClick, ready2Login }) => {
  const [showMsg, setShowMsg] = useState(false);
  return (
    <>
      <button
        type="button"
        className="login-with-google-btn"
        onClick={handleClick}
        disabled={!ready2Login}
      >
        Sign In With Google
      </button>
      <div
        className="smaller text-muted mt-1 pointer"
        onClick={() => setShowMsg(!showMsg)}
      >
        Login Options
      </div>
      {showMsg && (
        <p className="small m-3 text-muted text-center">
          Currently, access to Meal Buddy&trade; is by invitation-only and
          requires a Google account to log in.
        </p>
      )}
    </>
  );
};

const MealBuddyLogo = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="fs-5 text-center mb-4">
        Please sign in to get started...
      </div>
      <img
        src="/mb-logo-sq300.png"
        alt="Meal Buddy Logo"
        width="200"
        height="200"
      />
      <div
        className="smaller text-muted mt-2 pointer"
        onClick={() => navigate('/join')}
      >
        Tap here to join an existing account
      </div>
    </>
  );
};

const MealTicketLogo = ({ accountId, handleInputChange, ready2Type }) => {
  return (
    <>
      <div className="fs-5 text-center">
        Welcome to the exclusive Meal Buddy&trade; Members Only Club!
      </div>
      <div className="col-lg-5 col-md-10 mx-auto small text-muted my-3 px-3">
        Congratulations! You have been chosen to join an existing account. As a
        member, you will have the ability to change anything except the account
        settings. Sign in below and let's get this meal party started!
      </div>
      <img
        className="invite-logo"
        src="/invite.png"
        alt="Meal Buddy Invitation Logo"
      />
      <div className="small text-muted mt-2">
        Paste the <b>CODE</b> included with your invitation below:
      </div>
      <div className="col-lg-3 col-md-5 col-7 mx-auto mt-2">
        <input
          className="form-control text-center"
          id="accountId"
          type="text"
          value={accountId}
          onChange={handleInputChange}
          disabled={!ready2Type}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default Login;
