import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import AdBlock from '../Utils/AdBlock';

const Home = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <main>
      <div className="position-relative overflow-hidden p-3 text-center bg-body-tertiary">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          controls={false}
        >
          <Carousel.Item>
            <div className="col-10 col-md-6 p-lg-5 mx-auto">
              <img
                style={{ width: '100%', maxWidth: '600px' }}
                src="mb-tagline-main.png"
                alt="Meal Buddy Simplifies Your Life"
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="col-10 col-md-6 p-lg-5 mx-auto">
              <img
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                }}
                src="happy_couple_1.jpg"
                alt="Happy Couple 1"
              />
              <Carousel.Caption>
                <h3 className="p-lg-5">Make shopping fun!</h3>
              </Carousel.Caption>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="col-10 col-md-6 p-lg-5 mx-auto">
              <img
                style={{ width: '100%', maxWidth: '600px' }}
                src="mb-tagline-main.png"
                alt="Meal Buddy Simplifies Your Life"
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="col-10 col-md-6 p-lg-5 mx-auto">
              <img
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                }}
                src="happy_couple_2.jpg"
                alt="Happy Couple 2"
              />
              <Carousel.Caption>
                <h3 className="p-lg-5">Smiles in the aisles!</h3>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="d-md-flex justify-content-between my-md-3 ps-md-3">
        <div className="bg-body-tertiary me-md-3 py-3 px-3 pt-md-2 px-md-5 text-center overflow-hidden w-100">
          <div className="py-3">
            <h2 className="display-5">Effortless Meal Planning</h2>
            <p className="lead">
              Create weekly menus with a single tap and say goodbye to "what's
              for dinner?" dilemmas.
            </p>
          </div>
          <img
            className="bg-dark shadow-sm mx-auto"
            style={{
              width: '80%',
              maxWidth: '350px',
              borderRadius: '21px 21px 21px 21px',
            }}
            src="mb-screen-menu.jpg"
            alt="Meal Buddy Menu"
          />
        </div>

        <AdBlock />

        <div className="bg-body-tertiary me-md-3 py-3 px-3 pt-md-2 px-md-5 text-center overflow-hidden w-100">
          <div className="p-3">
            <h2 className="display-5">Smart Shopping Lists</h2>
            <p className="lead">
              Organize your grocery list by store and aisle number for the most
              efficient shopping trips ever.
            </p>
          </div>
          <img
            className="bg-dark shadow-sm mx-auto"
            style={{
              width: '80%',
              maxWidth: '350px',
              borderRadius: '21px 21px 21px 21px',
            }}
            src="mb-screen-shop.jpg"
            alt="Meal Buddy Shopping List"
          />
        </div>
      </div>

      <AdBlock />

      <div className="d-md-flex justify-content-between my-md-3 ps-md-3">
        <div className="bg-body-tertiary me-md-3 py-3 px-3 pt-md-2 px-md-5 text-center overflow-hidden w-100">
          <div className="p-3">
            <h2 className="display-5">Recipe Powerhouse</h2>
            <p className="lead">
              Save all your favorite recipes in one place, complete with notes
              and customizable grocery lists.
            </p>
          </div>
          <img
            className="bg-dark shadow-sm mx-auto"
            style={{
              width: '80%',
              maxWidth: '350px',
              borderRadius: '21px 21px 21px 21px',
            }}
            src="mb-screen-recipe.jpg"
            alt="Meal Buddy Recipe"
          />
        </div>

        <AdBlock />

        <div className="bg-body-tertiary me-md-3 py-3 px-3 pt-md-2 px-md-5 text-center overflow-hidden w-100">
          <div className="p-3">
            <h2 className="display-5">Web Recipe Magic</h2>
            <p className="lead">
              Import recipes from any website with our AI-powered tool - no more
              manual entry!
            </p>
          </div>
          <img
            className="bg-dark shadow-sm mx-auto"
            style={{
              width: '80%',
              maxWidth: '350px',
              borderRadius: '21px 21px 21px 21px',
            }}
            src="mb-screen-fetch.jpg"
            alt="Meal Buddy Web Download"
          />
        </div>
      </div>
    </main>
  );
};

export default Home;
